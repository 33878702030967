import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import { AdvancedSearch, LazyLoader } from '../../../../components';

import css from './SectionFeatures.module.css';

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionFeatures = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
    history,
    intl,
    location,
    routeConfiguration,
    withSectionSeparator,
    currentScrollFromTop
  } = props;

  // if(currentScrollFromTop === 0 && sectionId !== "hero") return null

  const [showPreloader, setShowPreloader] = useState(sectionId === "hero" && true)

  useEffect(() => {
    setTimeout(() => {
      setShowPreloader(false)
    }, 1000)
  }, [])

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = blocks?.length > 0;

  if (showPreloader) {
    return (
      <LazyLoader height="75" />
    )
  } else {
    return (
      <SectionContainer
        id={sectionId}
        className={className}
        rootClassName={rootClassName}
        appearance={appearance}
        options={fieldOptions}
        withSectionSeparator={withSectionSeparator}
      >
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field data={description} className={defaultClasses.description} options={fieldOptions} />
            <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
          </header>
        ) : null}


        {sectionId === "hero" && <AdvancedSearch history={history} location={location} intl={intl} routeConfiguration={routeConfiguration} />}

        {hasBlocks ? (
          <div
            className={classNames(defaultClasses.blockContainer, css.featuresMain, {
              [css.noSidePaddings]: isInsideContainer,
            })}
          >
            <BlockBuilder
              rootClassName={css.block}
              ctaButtonClass={defaultClasses.ctaButton}
              blocks={blocks}
              responsiveImageSizes="(max-width: 767px) 100vw, 568px"
              options={options}
            />
          </div>
        ) : null}
      </SectionContainer>
    );
  }
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeatures.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeatures.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFeatures;
